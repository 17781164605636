<template>
  <div id="view">
    <b-row class="mt-5 mb-5">
      <b-col>
        <h2 class="text-right">Formas de Pago</h2>
      </b-col>
    </b-row>
    <div class="spacer h125"></div>
    <transition name="fade" mode="out-in">
      <b-container>
        <b-row class="mt-5">
          <b-col cols="6" offset="3" class="text-center">
            <label for="forma" class="d-block"
              >Selecciona un método de pago</label
            >
            <div class="select">
              <select
                name="forma"
                id="forma"
                class="d-block mx-auto mb-5"
                v-model="forma"
                @change="setTipos"
              >
                <option value="">Selecciona una opción</option>
                <option
                  :value="forma.id"
                  v-for="forma in datos"
                  :key="forma.id"
                  :data-icons="forma.iconos"
                >
                  {{ forma.nombre }}
                </option>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3" v-show="forma != 0">
          <b-col cols="12" class="text-center">
            <input type="text" class="isLabel" v-model="tipoLabel" readonly />
          </b-col>
          <b-col cols="6" offset="3">
            <b-row class="text-center">
              <b-col cols="4" v-for="(icono, index) in iconos" :key="index">
                <input
                  type="radio"
                  name="tipo"
                  :value="icono"
                  :id="'icono-' + index"
                  :checked="index == 0"
                />
                <label :for="'icono-' + index" class="icono__tipo">
                  <font-awesome-icon :icon="['fas', icono]" size="lg" />
                  <div class="checkedItem">
                    <img
                      src="@/assets/img/checkedItem.svg"
                      alt="is checked option"
                    />
                  </div>
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </transition>
  </div>
</template>
<style lang="scss" scoped>
:focus {
  outline: none;
}
.spacer {
  display: block;
  clear: both;
  &.h125 {
    height: 125px;
  }
}
h2 {
  font-size: 30px;
  color: var(--gris-100);
}
label {
  font-size: 1.25rem;
  font-weight: bold;
  color: #777777;
  margin-bottom: 20px;
}
.isLabel {
  border: none;
  background: transparent;
  font-size: 2.5rem;
  text-align: center;
  color: #777777;
  margin-top: 50px;
}
[name='tipo'] {
  display: none;
}
.select {
  height: 30px;
  border-radius: 15px;
  background: #0085ff;
  width: 230px;
  padding: 0 15px;
  margin: auto;
  select {
    background: transparent;
    width: 100%;
    height: 100%;
    display: block;
    color: var(--blanco);
    font-size: 13px;
    border: none;
    font-weight: 600;
  }
}
.icono__tipo {
  background: var(--blanco);
  height: 200px;
  border-radius: 8px;
  border: 1px solid #e3e8ed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d3d3d3;
  cursor: pointer;
  transition: all ease 0.5s;
  position: relative;
  font-size: 35px;
}
.checkedItem {
  position: absolute;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all ease 0.5s;
}
[name='tipo']:checked + label {
  background-image: linear-gradient(-45deg, #64e4ac 0%, #46bb88 50%);
  color: var(--blanco);
}
[name='tipo']:checked + label .checkedItem {
  opacity: 1;
}
</style>
<script>
import axios from 'axios'
export default {
  name: 'FormasDePago',
  data() {
    return {
      sesion_cliente: '',
      datos: [],
      iconos: [],
      forma: 1,
      tipoLabel: '',
    }
  },
  methods: {
    listarDatos: async function () {
      try {
        const response = await axios.get(
          'https://api.thelegacygroup.mx/formas-de-pago',
          {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        )
        this.datos = response.data
      } catch (error) {
        console.error(error)
      }
    },
    setTipos: function (e) {
      if (e.target.options.selectedIndex != 0) {
        this.tipoLabel = e.target.options[e.target.options.selectedIndex].text
        this.setIconos(e)
      }
    },
    setIconos: function (e) {
      let stringIconos = e.target.options[
        e.target.options.selectedIndex
      ].getAttribute('data-icons')
      if (stringIconos != '') {
        stringIconos = stringIconos.split(',')
      }
      this.iconos = stringIconos
      console.log(this.iconos)
    },
  },
  mounted() {
    if ('sesion_cliente' in localStorage) {
      this.listarDatos()
      this.sesion_cliente = JSON.parse(localStorage.getItem('sesion_cliente'))
      this.token = this.sesion_cliente.token
    } else {
      this.$router.push('/')
    }
  },
}
</script>
