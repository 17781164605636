<template>
  <div class="login">
    <div class="background-green"></div>
    <div id="login-container">
      <div id="login-container-left">
        <img
          src="@/assets/img/logo-login.svg"
          alt=""
          class="img-fluid d-block mx-auto my-4"
        />
        <p class="mt-2">Tu aliado en inversiones</p>
      </div>
      <div id="login-container-right">
        <div id="login-form">
          <form id="formulario-login" method="POST" @submit.prevent="loginForm">
            <h2 class="mb-4 text-center">Iniciar sesión</h2>
            <div class="form-group mb-4">
              <label for="usuario" class="form-label mt-2"
                >Correo electrónico</label
              >
              <input
                type="text"
                name="usuario"
                id="usuario"
                class="form-control"
                autocomplete="OFF"
                v-model="login.usuario"
              />

              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.usuario.required"
                >Este campo es obligatorio</span
              >
              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.usuario.minLength"
                >Escriba al menos 4 caracteres</span
              >
              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.usuario.email"
                >Escriba un correo válido</span
              >
            </div>
            <div class="form-group mb-4">
              <label for="password" class="form-label">Contraseña</label>
              <input
                type="password"
                name="password"
                id="password"
                class="form-control"
                autocomplete="OFF"
                v-model="login.password"
              />
              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.password.required"
                >Este campo es obligatorio</span
              >
              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.password.minLength"
                >Escriba al menos 2 caracteres</span
              >
              <span
                class="text-danger d-block w-100"
                v-if="submited && !$v.login.password.maxLength"
                >Escriba hasta 20 caracteres</span
              >
            </div>
            <div>
              <button class="btn btn-success d-block w-100 mb-4">
                Ingresar
              </button>
              <a href="javascript;">Olvidé mi contraseña</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.background-green {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  width: 45%;
  height: 100%;
  background: var(--secundario);
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
}
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 85%;
    z-index: 1;
    &-left {
      p {
        color: var(--gris);
        text-align: center;
        font-size: 20px;
        font-weight: 400;
      }
    }
    &-right {
      background: var(--blanco);
      width: 33%;
      min-height: 440px;
      padding: 40px 30px;
      border-radius: 10px;
      h2 {
        font-size: 32px;
        font-weight: 700;
      }
    }
  }
  &-form {
    label,
    button {
      font-size: 1.3rem;
    }
    button,
    input {
      height: 50px;
    }
    a {
      text-align: center;
      width: 100%;
      display: block;
      font-size: 1.1rem;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import axios from 'axios'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      submited: false,
      login: {
        usuario: '',
        password: '',
      },
    }
  },
  validations: {
    login: {
      usuario: {
        required,
        email,
        minLength: minLength(4),
      },
      password: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20),
      },
    },
  },
  methods: {
    loginForm: async function () {
      this.submited = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('fallo la validación')
        return false
      }
      try {
        const response = await axios.post(
          'https://api.thelegacygroup.mx/login',
          {
            usuario: this.login.usuario,
            password: this.login.password,
          }
        )
        if (response.data.status == 1) {
          localStorage.setItem(
            'sesion_cliente',
            JSON.stringify({
              nombre: response.data.nombre,
              imagen: response.data.imagen,
              id: response.data.id,
              token: response.data.token,
            })
          )
          this.$router.push('/objetivos')
        } else {
          this.$swal('Error', response.data.texto, 'error')
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
  mounted() {
    if ('sesion_cliente' in localStorage) {
      this.$router.push('/objetivos')
    } else {
      this.$router.push('/')
    }
  },
}
</script>
