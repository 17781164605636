<template>
  <div id="app">
    <navBar v-if="$route.name !== 'Home'" @contraerMenu="isContract" />
    <div id="general" :class="$route.name !== 'Home' ? '' : 'loginView'">
      <b-container fluid style="height: 100%">
        <b-row style="height: 100%">
          <b-col class="px-5">
            <router-view />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;0,900;1,400&display=swap');
:root {
  --blanco: #fff;
  --gris: #b7b7b7;
  --gris-100: #d8dbdd;
  --negro: #707070;
  --negro-100: #121212;
  --negro-200: #767676;
  --negro-300: #222;
  --rojo: #e1005b;
  --azul: #0085ff;
  --secundario: #69e2ad;
  --tercero: #4cd197;
  --font-size-base: 13px;
  --turquesa: #007c88;
  --turquesa-100: #0f96a3;
  --turquesa-200: #40b6c0;
  --turquesa-300: #7ac7cf;
  --morado: #373ba6;
  --morado-100: #5b5fd2;
  --morado-200: #7c7fd6;
  --morado-300: #a3a6eb;
  --magenta: #7b1161;
  --magenta-100: #b12d91;
  --magenta-200: #d06ab7;
  --magenta-300: #dba5ce;
  --coral: #c15043;
  --coral-100: #ec6f5f;
  --coral-200: #ee8377;
  --coral-300: #f1a9a1;
  --olivo: #c19706;
  --olivo-100: #c4a12a;
  --olivo-200: #d6b74b;
  --olivo-300: #d9c067;
  --pistache: #7c9607;
  --pistache-100: #91a538;
  --pistache-200: #a4b945;
  --pistache-300: #bece71;
}
html,
body,
#app {
  font-family: 'Roboto', sans-serif;
  font-size: var(--font-size-base);
  background: #fafafa;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.pointer {
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.1);
  }
}
#general {
  width: calc(100% - 182px);
  float: right;
  height: 100vh;
  transition: all 1s ease;
  &.loginView {
    width: 100%;
    background: var(--negro-300);
  }
}
#bars {
  position: absolute;
  left: 0;
  display: flex;
  z-index: 3;
}
.active {
  background: var(--secundario);
}
.ausente {
  background: var(--rojo) !important;
}
.tblanco {
  color: var(--blanco);
}
.icon {
  width: 22px;
  margin-right: 5px;
  float: left;
  text-align: center;
}
.color {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
  &-turquesa {
    background: var(--turquesa);
  }
  &-turquesa-100 {
    background: var(--turquesa-100);
  }
  &-turquesa-200 {
    background: var(--turquesa-200);
  }
  &-turquesa-300 {
    background: var(--turquesa-300);
  }
  &-morado {
    background: var(--morado);
  }
  &-morado-100 {
    background: var(--morado-100);
  }
  &-morado-200 {
    background: var(--morado-200);
  }
  &-morado-300 {
    background: var(--morado-300);
  }
  &-magenta {
    background: var(--magenta);
  }
  &-magenta-100 {
    background: var(--magenta-100);
  }
  &-magenta-200 {
    background: var(--magenta-200);
  }
  &-magenta-300 {
    background: var(--magenta-300);
  }
  &-coral {
    background: var(--coral);
  }
  &-coral-100 {
    background: var(--coral-100);
  }
  &-coral-200 {
    background: var(--coral-200);
  }
  &-coral-300 {
    background: var(--coral-300);
  }
  &-olivo {
    background: var(--olivo);
  }
  &-olivo-100 {
    background: var(--olivo-100);
  }
  &-olivo-200 {
    background: var(--olivo-200);
  }
  &-olivo-300 {
    background: var(--olivo-300);
  }
  &-pistache {
    background: var(--pistache);
  }
  &-pistache-100 {
    background: var(--pistache-100);
  }
  &-pistache-200 {
    background: var(--pistache-200);
  }
  &-pistache-300 {
    background: var(--pistache-300);
  }
}
.t {
  &turquesa {
    color: var(--turquesa);
  }
  &turquesa-100 {
    color: var(--turquesa-100);
  }
  &turquesa-200 {
    color: var(--turquesa-200);
  }
  &turquesa-300 {
    color: var(--turquesa-300);
  }
  &morado {
    color: var(--morado);
  }
  &morado-100 {
    color: var(--morado-100);
  }
  &morado-200 {
    color: var(--morado-200);
  }
  &morado-300 {
    color: var(--morado-300);
  }
  &magenta {
    color: var(--magenta);
  }
  &magenta-100 {
    color: var(--magenta-100);
  }
  &magenta-200 {
    color: var(--magenta-200);
  }
  &magenta-300 {
    color: var(--magenta-300);
  }
  &coral {
    color: var(--coral);
  }
  &coral-100 {
    color: var(--coral-100);
  }
  &coral-200 {
    color: var(--coral-200);
  }
  &coral-300 {
    color: var(--coral-300);
  }
  &olivo {
    color: var(--olivo);
  }
  &olivo-100 {
    color: var(--olivo-100);
  }
  &olivo-200 {
    color: var(--olivo-200);
  }
  &olivo-300 {
    color: var(--olivo-300);
  }
  &pistache {
    color: var(--pistache);
  }
  &pistache-100 {
    color: var(--pistache-100);
  }
  &pistache-200 {
    color: var(--pistache-200);
  }
  &pistache-300 {
    color: var(--pistache-300);
  }
}
.gradiente- {
  &olivo {
    background-image: linear-gradient(270deg, var(--olivo) 0%, #d6b74b 50%);
  }
  &olivo-100 {
    background-image: linear-gradient(270deg, var(--olivo-100) 0%, #d6b74b 50%);
  }
  &olivo-200 {
    background-image: linear-gradient(270deg, var(--olivo-200) 0%, #d6b74b 50%);
  }
  &olivo-300 {
    background-image: linear-gradient(270deg, var(--olivo-300) 0%, #d6b74b 50%);
  }
  &turquesa {
    background-image: linear-gradient(270deg, #025e66 0%, var(--turquesa) 50%);
  }
  &turquesa-100 {
    background-image: linear-gradient(
      270deg,
      #0d818c 0%,
      var(--turquesa-100) 50%
    );
  }
  &turquesa-200 {
    background-image: linear-gradient(
      270deg,
      #318d94 0%,
      var(--turquesa-200) 50%
    );
  }
  &turquesa-300 {
    background-image: linear-gradient(
      270deg,
      #6dafb5 0%,
      var(--turquesa-300) 50%
    );
  }
  &coral {
    background-image: linear-gradient(270deg, var(--coral) 0%, #ec6f5f 50%);
  }
  &coral-100 {
    background-image: linear-gradient(270deg, var(--coral-100) 0%, #ec6f5f 50%);
  }
  &coral-200 {
    background-image: linear-gradient(270deg, var(--coral-200) 0%, #ec6f5f 50%);
  }
  &coral-300 {
    background-image: linear-gradient(270deg, var(--coral-300) 0%, #ec6f5f 50%);
  }
  &morado {
    background-image: linear-gradient(270deg, #373ba6 0%, var(--morado) 50%);
  }
  &morado-100 {
    background-image: linear-gradient(
      270deg,
      #373ba6 0%,
      var(--morado-100) 50%
    );
  }
  &morado-200 {
    background-image: linear-gradient(
      270deg,
      #373ba6 0%,
      var(--morado-200) 50%
    );
  }
  &morado-300 {
    background-image: linear-gradient(
      270deg,
      #373ba6 0%,
      var(--morado-300) 50%
    );
  }
  &magenta {
    background-image: linear-gradient(270deg, #7b1161 0%, var(--magenta) 50%);
  }
  &magenta-100 {
    background-image: linear-gradient(
      270deg,
      #7b1161 0%,
      var(--magenta-100) 50%
    );
  }
  &magenta-200 {
    background-image: linear-gradient(
      270deg,
      #7b1161 0%,
      var(--magenta-200) 50%
    );
  }
  &magenta-300 {
    background-image: linear-gradient(
      270deg,
      #7b1161 0%,
      var(--magenta-300) 50%
    );
  }
  &pistache {
    background-image: linear-gradient(
      270deg,
      var(--pistache) 0%,
      var(--pistache) 50%
    );
  }
  &pistache-100 {
    background-image: linear-gradient(
      270deg,
      var(--pistache) 0%,
      var(--pistache-100) 50%
    );
  }
  &pistache-200 {
    background-image: linear-gradient(
      270deg,
      var(--pistache) 0%,
      var(--pistache-200) 50%
    );
  }
  &pistache-300 {
    background-image: linear-gradient(
      270deg,
      var(--pistache) 0%,
      var(--pistache-300) 50%
    );
  }
}
.router-link-active:hover,
.router-link-exact-active {
  svg {
    color: var(--secundario);
  }
  span {
    color: var(--blanco);
  }
}
</style>
<script>
import NavBar from './components/NavBar'
export default {
  name: 'App',
  components: {
    NavBar,
  },
  methods: {
    isContract(contraido) {
      if (contraido === true) {
        document.getElementById('general').style.width = 'calc(100% - 70px)'
      } else {
        document.getElementById('general').style.width = 'calc(100% - 182px)'
      }
    },
  },
}
</script>
