<template>
  <div class="pickerColor" @click="expandir = !expandir">
    <div class="color" :class="'color-' + color"></div>
    <font-awesome-icon :icon="['fas', 'chevron-down']" size="2x" />
    <div class="selectorColor" v-show="expandir">
      <div class="arrow-up"></div>
      <div class="colors">
        <div
          @click="selectColor('turquesa')"
          class="color color-turquesa"
        ></div>
        <div
          @click="selectColor('turquesa-100')"
          class="color color-turquesa-100"
        ></div>
        <div
          @click="selectColor('turquesa-200')"
          class="color color-turquesa-200"
        ></div>
        <div
          @click="selectColor('turquesa-300')"
          class="color color-turquesa-300"
        ></div>
        <div @click="selectColor('morado')" class="color color-morado"></div>
        <div
          @click="selectColor('morado-100')"
          class="color color-morado-100"
        ></div>
        <div
          @click="selectColor('morado-200')"
          class="color color-morado-200"
        ></div>
        <div
          @click="selectColor('morado-300')"
          class="color color-morado-300"
        ></div>
        <div @click="selectColor('magenta')" class="color color-magenta"></div>
        <div
          @click="selectColor('magenta-100')"
          class="color color-magenta-100"
        ></div>
        <div
          @click="selectColor('magenta-200')"
          class="color color-magenta-200"
        ></div>
        <div
          @click="selectColor('magenta-300')"
          class="color color-magenta-300"
        ></div>
        <div @click="selectColor('coral')" class="color color-coral"></div>
        <div
          @click="selectColor('coral-100')"
          class="color color-coral-100"
        ></div>
        <div
          @click="selectColor('coral-200')"
          class="color color-coral-200"
        ></div>
        <div
          @click="selectColor('coral-300')"
          class="color color-coral-300"
        ></div>
        <div @click="selectColor('olivo')" class="color color-olivo"></div>
        <div
          @click="selectColor('olivo-100')"
          class="color color-olivo-100"
        ></div>
        <div
          @click="selectColor('olivo-200')"
          class="color color-olivo-200"
        ></div>
        <div
          @click="selectColor('olivo-300')"
          class="color color-olivo-300"
        ></div>
        <div
          @click="selectColor('pistache')"
          class="color color-pistache"
        ></div>
        <div
          @click="selectColor('pistache-100')"
          class="color color-pistache-100"
        ></div>
        <div
          @click="selectColor('pistache-200')"
          class="color color-pistache-200"
        ></div>
        <div
          @click="selectColor('pistache-300')"
          class="color color-pistache-300"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PickerColor',
  data() {
    return {
      expandir: false,
    }
  },
  props: {
    color: String,
    id: Number,
  },
  methods: {
    selectColor: function (color) {
      let datos = {
        id: this.id,
        color: color,
      }
      this.$emit('changeColor', datos)
      this.$emit('selectNewColor', color)
    },
  },
}
</script>
<style lang="scss" scoped>
.pickerColor {
  padding: 3px;
  height: 22px;
  width: 37px;
  background: var(--blanco);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  .color {
    width: 16px;
    height: 16px;
    align-self: center;
    justify-self: center;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    }
  }
  svg {
    font-size: 12px;
    color: #777777;
  }
  .selectorColor {
    width: 110px;
    height: 164px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 32px;
    right: -32px;
    z-index: 999;
    .colors {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 13px;
      grid-column-gap: 5px;
      grid-row-gap: 7px;
    }
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid var(--blanco);
      position: absolute;
      top: -8px;
      right: 35px;
    }
  }
}
</style>
