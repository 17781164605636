import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faLandmark,
  faLightbulb,
  faCommentDollar,
  faExclamationTriangle,
  faShieldAlt,
  faChartBar,
  faCog,
  faTimesCircle,
  faDotCircle,
  faCity,
  faThLarge,
  faBars,
  faPlus,
  faPen,
  faSignOutAlt,
  faMoneyBill,
  faMoneyCheck,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
const configuracionesSwal = {
  confirmButtonColor: '#28a745',
  cancelButtonColor: '#dc3545',
}

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueSweetalert2, configuracionesSwal)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
library.add(
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faLandmark,
  faLightbulb,
  faCommentDollar,
  faExclamationTriangle,
  faShieldAlt,
  faChartBar,
  faCog,
  faTimesCircle,
  faDotCircle,
  faCity,
  faThLarge,
  faBars,
  faPlus,
  faPen,
  faSignOutAlt,
  faMoneyBill,
  faMoneyCheck,
  faCreditCard
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
