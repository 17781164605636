import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    visibleSecondBar: false,
  },
  mutations: {
    showSecondBar(state) {
      state.visibleSecondBar = true
    },
    hideSecondBar(state) {
      state.visibleSecondBar = false
    },
  },
  getters: {
    getVisibleSecondBar: (state) => state.visibleSecondBar,
  },
  actions: {},
  modules: {},
})
