<template>
  <div id="secondBar" class="pl-4" v-show="visible">
    <b-container fluid>
      <b-row>
        <b-col>
          <font-awesome-icon
            :icon="['fas', 'times-circle']"
            size="lg"
            pull="right"
            class="mt-4 tblanco"
            @click="hideSecondBar"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="mb-4 mt-2">
      <h2>Configuración</h2>
    </div>
    <div id="second-nav" class="pt-4">
      <ul>
        <li>
          <router-link to="/objetivos" @click.native="hideSecondBar">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'dot-circle']" size="lg" />
            </div>
            <span>Objetivos</span>
          </router-link>
        </li>
        <li>
          <router-link to="/tipos-de-inversion" @click.native="hideSecondBar">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'chart-bar']" size="lg" />
            </div>
            <span>Tipos de inversión</span>
          </router-link>
        </li>
        <li>
          <router-link to="/empresas" @click.native="hideSecondBar"
            ><div class="icon">
              <font-awesome-icon :icon="['fas', 'city']" size="lg" />
            </div>
            <span>Empresas</span>
          </router-link>
        </li>
        <li>
          <router-link to="/formas-de-pago" @click.native="hideSecondBar"
            ><div class="icon">
              <font-awesome-icon :icon="['fas', 'credit-card']" size="lg" />
            </div>
            <span>Formas de pago</span>
          </router-link>
        </li>
        <li>
          <div @click="logout()" class="logout">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="lg" />
            </div>
            <span>Cerrar Sesión</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SecondBar',
  props: {
    visible: Boolean,
  },
  methods: {
    hideSecondBar: function () {
      this.$emit('click', !this.visible)
      const configuracionPrincipal = document.getElementById(
        'configuracionPrincipal'
      )
      configuracionPrincipal.classList.add('active')
    },
    logout() {
      document.getElementById('general').style.width = '100%'
      localStorage.removeItem('sesion_cliente')
      this.$router.push('/')
    },
  },
}
</script>

<style scoped lang="scss">
#secondBar {
  background: var(--negro-100);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 182px;
  h2 {
    font-size: 20px;
    font-weight: 600;
    color: var(--negro-200);
  }
  svg {
    cursor: pointer;
  }
}

#second-nav {
  ul {
    list-style: none;
    padding-left: 0;
    line-height: 1.7;
    margin-left: 6px;
    li {
      margin-bottom: 15px;
      a {
        color: var(--gray);
      }
    }
  }
}
.logout {
  cursor: pointer;
  span,
  .icon {
    color: var(--gray);
  }
  &:hover {
    svg {
      color: var(--secundario);
    }
    span {
      color: var(--blanco);
    }
  }
}
</style>
