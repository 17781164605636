<template>
  <div id="view">
    <b-row class="mt-5">
      <b-col class="views" v-if="datos.length > 0">
        <font-awesome-icon
          :icon="['fas', 'th-large']"
          size="lg"
          class="mt-3 mr-3"
          :class="cuadricula === true ? 'viewActive' : ''"
          @click="cuadricula = true"
        />
        <font-awesome-icon
          :icon="['fas', 'bars']"
          size="lg"
          class="mt-3"
          :class="cuadricula === false ? 'viewActive' : ''"
          @click="cuadricula = false"
        />
      </b-col>
      <b-col>
        <h2 class="text-right">Tipos de Inversión</h2>
      </b-col>
    </b-row>
    <transition name="fade" mode="out-in">
      <b-row v-if="datos.length == 0" class="alert alert-warning mt-5">
        <p class="text-center w-100 mb-0">No hay registros disponibles</p>
      </b-row>
    </transition>
    <transition name="fade" mode="out-in">
      <b-row class="mt-3" v-if="cuadricula">
        <b-col
          cols="6"
          class="cuadricula"
          v-for="(dato, index) in datos"
          :key="index"
        >
          <div class="cuadriculaContainer" :class="'gradiente-' + dato.color">
            <div class="herramientas">
              <PickerColor
                :id="parseInt(dato.id)"
                :color="dato.color"
                @changeColor="changeColor"
              />
              <div class="circle">
                <font-awesome-icon
                  :icon="['fas', 'pen']"
                  size="1x"
                  pull="right"
                  class="pointer"
                  :class="'t' + dato.color"
                  @click="showUpdateForm(dato)"
                />
              </div>
              <font-awesome-icon
                :icon="['fas', 'times-circle']"
                size="2x"
                pull="right"
                class="tblanco pointer"
                @click="deleteRecord(dato.id)"
              />
            </div>
            <h3>{{ dato.nombre }}</h3>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="!cuadricula">
        <b-col cols="12">
          <div class="list mb-3" v-for="(dato, index) in datos" :key="index">
            <div class="container-color">
              <div class="color" :class="'color-' + dato.color"></div>
            </div>
            <h3>{{ dato.nombre }}</h3>
            <div class="acciones" :class="'gradiente-' + dato.color">
              <PickerColor
                :id="parseInt(dato.id)"
                :color="dato.color"
                @changeColor="changeColor"
              />
              <div class="circle">
                <font-awesome-icon
                  :icon="['fas', 'pen']"
                  size="1x"
                  pull="right"
                  class="pointer"
                  :class="'t' + dato.color"
                  @click="showUpdateForm(dato)"
                />
              </div>
              <font-awesome-icon
                :icon="['fas', 'times-circle']"
                size="2x"
                class="pointer"
                @click="deleteRecord(dato.id)"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </transition>
    <b-row id="containerNewButton">
      <b-col cols="12">
        <div id="addData" class="button button-blue" @click="showForm">
          <font-awesome-icon :icon="['fas', 'plus']" size="lg" />
          <span>Crear Tipo</span>
        </div>
      </b-col>
    </b-row>
    <div id="formulario" v-show="entradas" :class="'color-' + newColor">
      <div id="formularioTitulo">
        <h3>Añadir Tipo</h3>
      </div>
      <div id="formularioCerrar">
        <font-awesome-icon
          :icon="['fas', 'times-circle']"
          size="lg"
          class="mt-4 tblanco pointer"
          @click="entradas = false"
        />
      </div>
      <div id="formularioCampos">
        <input
          type="text"
          autocomplete="OFF"
          placeholder="Escribe tu nuevo objetivo"
          ref="nombre"
          v-model="nombre"
        />
        <span
          class="text-danger d-block w-100"
          v-if="submited && !$v.nombre.required"
          >Este campo es requerido</span
        >
        <span
          class="text-danger d-block w-100"
          v-if="submited && !$v.nombre.minLength"
          >Escriba al menos 2 caracteres</span
        >
        <span
          class="text-danger d-block w-100"
          v-if="submited && !$v.nombre.maxLength"
          >Escriba hasta 100 caracteres</span
        >
        <span>(Estilo de vida, inversión, etc.)</span>
        <label for="color">Selecciona un color</label>
        <PickerColor :color="newColor" @selectNewColor="selectNewColor" />
        <span
          class="text-danger d-block w-100"
          v-if="submited && !$v.newColor.required"
          >Este campo es requerido</span
        >
        <button
          id="formularioBoton"
          class="btn-gral bg-white d-block mx-auto mt-5"
          @click="insertRecord"
        >
          {{ textoBoton }}
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
[class*='color-'] {
  transition: all 1s;
}
.views {
  svg {
    color: var(--gris);
    cursor: pointer;
    &:hover {
      color: var(--azul);
    }
  }
}
.viewActive {
  color: var(--azul) !important;
}
h2 {
  font-size: 30px;
  color: var(--gris-100);
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: var(--blanco);
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.05);
  }
}
.button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.5s ease;
  span {
    color: var(--blanco);
    font-size: 0;
    transition: all 0.5s ease;
  }
  &:hover {
    transform: scale(1.1);
    width: 180px;
    border-radius: 35px;
  }
  &:hover span {
    margin-left: 10px;
    font-size: 18px;
  }
  svg {
    color: var(--blanco);
  }
  &-blue {
    background: var(--azul);
  }
}
.cuadricula {
  margin-bottom: 2.3rem;
  &Container {
    border-radius: 8px;
    min-height: 120px;
    padding: 30px 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.05);
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
      z-index: 2;
    }
    &:hover .herramientas {
      opacity: 1;
    }
  }
  h3 {
    color: var(--blanco);
    font-size: 1.9rem;
    transition: all 0.5s ease;
  }
}

.herramientas {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
  svg {
    margin-left: 0;
  }
}
.list {
  padding: 10px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: var(--blanco);
  border-radius: 25px;
  &:nth-child(odd) {
    background: #f1f1f1;
  }
  h3 {
    width: 90%;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--negro);
    overflow: hidden;
  }
  svg.fa-times-circle {
    color: var(--blanco);
  }
}
.container-color {
  height: 50px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acciones {
  width: 125px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#containerNewButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
#formulario {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 2;
  &Titulo {
    margin-bottom: 7rem;
    h3 {
      opacity: 0.5;
      font-size: 30px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: right;
      line-height: 28px;
    }
  }
  &Cerrar {
    text-align: center;
    margin-bottom: 6.3rem;
    svg {
      font-size: 32px;
      opacity: 0.8;
    }
  }
  &Campos {
    input {
      font-size: 40px;
      color: var(--blanco);
      background: transparent;
      border: none;
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 0.65rem;
      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        outline: none;
      }
    }
    span,
    label {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      color: var(--blanco);
      display: block;
    }
    span {
      margin-bottom: 6rem;
    }
    .pickerColor {
      margin: auto;
      height: 30px;
      width: 50px;
    }
    .btn-gral {
      border: none;
      border-radius: 16px;
      font-size: 14px;
      color: var(--blanco);
      background: var(--secundario);
      padding: 5px 11px;
      &.bg-white {
        color: var(--azul);
        background: var(--blanco);
      }
    }
  }
}
</style>
<script>
import PickerColor from './../../components/PickerColor'
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'TiposDeInversion',
  components: {
    PickerColor,
  },
  data() {
    return {
      cuadricula: true,
      expadir: false,
      entradas: false,
      newColor: 'morado',
      submited: false,
      editando: false,
      sesion_cliente: '',
      token: '',
      textoBoton: 'Guardar Tipo',
      nombre: '',
      datos: [],
      editado: {},
    }
  },
  validations: {
    newColor: {
      required,
    },
    nombre: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100),
    },
  },
  methods: {
    showForm() {
      this.entradas = true
      this.editando = false
      this.nombre = ''
      this.newColor = 'morado'
      this.textoBoton = 'Guardar Tipo'
      this.$refs.nombre.focus()
    },
    showUpdateForm(dato) {
      this.editado = dato
      this.editando = true
      this.textoBoton = 'Editar Tipo'
      this.entradas = true
      this.nombre = this.editado.nombre
      this.newColor = this.editado.color
    },
    selectNewColor: function (color) {
      this.newColor = color
    },
    changeColor: async function (data) {
      let colorSeleccionado = this.datos.find(
        (objetivo) => objetivo.id == data.id
      )
      const response = await axios.patch(
        'https://api.thelegacygroup.mx/tipos-de-inversion',
        data,
        {
          headers: {
            Authorization: 'Bearer ' + this.token,
          },
        }
      )
      this.$swal(
        response.data.titulo,
        response.data.texto,
        response.data.status
      )
      colorSeleccionado.color = data.color
    },
    listarDatos: async function () {
      try {
        const response = await axios.get(
          'https://api.thelegacygroup.mx/tipos-de-inversion',
          {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        )
        this.datos = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async insertRecord() {
      this.submited = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('fallo la validación')
        return false
      }
      const sesion_cliente = JSON.parse(localStorage.getItem('sesion_cliente'))
      let obj = {
        nombre: this.nombre,
        color: this.newColor,
        usuario: sesion_cliente.id,
      }
      let response = {}
      if (this.editando === true) {
        obj['id'] = this.editado.id
        response = await axios.put(
          'https://api.thelegacygroup.mx/tipos-de-inversion',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        )
      } else {
        response = await axios.post(
          'https://api.thelegacygroup.mx/tipos-de-inversion',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        )
      }
      this.$swal.fire(
        response.data.titulo,
        response.data.message,
        response.data.status
      )
      this.entradas = false
      this.listarDatos()
    },
    deleteRecord: function (id) {
      this.$swal({
        title: '¿Está seguro?',
        text: 'Al aceptar, borrará el registro permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar!',
        cancelButtonText: 'No, Cancelar',
      }).then((result) => {
        if (result.value) {
          axios
            .delete('https://api.thelegacygroup.mx/tipo-de-inversion/' + id, {
              headers: {
                Authorization: 'Bearer ' + this.token,
              },
            })
            .then((response) => {
              this.$swal.fire(
                response.data.titulo,
                response.data.texto,
                response.data.tipo
              )
              this.listarDatos()
            })
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal.fire('Cancelado', 'No se eliminaron los datos', 'error')
        }
      })
    },
  },
  mounted() {
    if ('sesion_cliente' in localStorage) {
      this.listarDatos()
      this.sesion_cliente = JSON.parse(localStorage.getItem('sesion_cliente'))
      this.token = this.sesion_cliente.token
    } else {
      this.$router.push('/')
    }
  },
}
</script>
