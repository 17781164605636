import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Objetivos from '../views/catalogos/Objetivos.vue'
import TiposDeInversion from '../views/catalogos/TiposDeInversion.vue'
import FormasDePago from '../views/catalogos/FormasDePago.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/objetivos',
    name: 'Objetivos',
    component: Objetivos,
  },
  {
    path: '/tipos-de-inversion',
    name: 'TiposDeInversion',
    component: TiposDeInversion,
  },
  {
    path: '/formas-de-pago',
    name: 'FormasDePago',
    component: FormasDePago,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
