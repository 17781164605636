<template>
  <div id="bars">
    <div id="sidebar" class="px-4" :class="contraido === true ? 'compact' : ''">
      <font-awesome-icon
        :icon="
          contraido === false
            ? ['fas', 'chevron-left']
            : ['fas', 'chevron-right']
        "
        size="lg"
        class="mt-4 tblanco contraerMenu"
        :class="
          contraido === false
            ? 'float-right'
            : ['float: none', 'mx-auto', 'mb-4', 'd-block']
        "
        @click="contraerMenu"
      />
      <div class="clearfix"></div>
      <div
        class="logo mb-4 mt-2"
        :class="contraido === true ? 'text-center' : ''"
      >
        <img
          :src="
            contraido === true
              ? require('@/assets/img/logo-contract.svg')
              : require('@/assets/img/logo.svg')
          "
          alt="Logotipo Legacy Group"
        />
      </div>
      <div id="nav" class="pt-3">
        <ul>
          <li :class="contraido === true ? 'text-center' : ''">
            <router-link to="/">
              <div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon :icon="['fas', 'landmark']" size="lg" />
              </div>
              <span v-show="!contraido">Cuentas bancarias</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/">
              <div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon :icon="['fas', 'lightbulb']" size="lg" />
              </div>
              <span v-show="!contraido">Proyectos</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/"
              ><div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon
                  :icon="['fas', 'comment-dollar']"
                  size="lg"
                />
              </div>
              <span v-show="!contraido">Ingresos</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/"
              ><div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon
                  :icon="['fas', 'exclamation-triangle']"
                  size="lg"
                />
              </div>
              <span v-show="!contraido">Deudas</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/"
              ><div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon :icon="['fas', 'money-bill']" size="lg" />
              </div>
              <span v-show="!contraido">Prestamos</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/"
              ><div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon :icon="['fas', 'shield-alt']" size="lg" />
              </div>
              <span v-show="!contraido">Seguros</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/"
              ><div
                class="icon"
                :class="contraido === true ? ['float-none', 'mx-auto'] : ''"
              >
                <font-awesome-icon :icon="['fas', 'chart-bar']" size="lg" />
              </div>
              <span v-show="!contraido">Reportes</span>
              <div class="clearfix"></div>
            </router-link>
          </li>
        </ul>
      </div>
      <div id="configuracion">
        <div id="sesion" class="d-flex align-items-center">
          <div id="sesionImagen">
            <div id="sesionEstado" class="ausente"></div>
            <img
              src="@/assets/img/user-sample.png"
              alt="Usuario Sesión"
              class="img-fluid"
            />
          </div>
          <p v-show="!contraido">{{ usuario }}</p>
        </div>
        <div
          id="configuracionPrincipal"
          :class="[isCompact, isActive]"
          @click="toggleSecondBar"
        >
          <font-awesome-icon :icon="['fas', 'cog']" size="lg" />
          <span v-show="!contraido">Configuración</span>
        </div>
      </div>
    </div>
    <SecondBar :visible="visibleSecondBar" @click="hideSecondBar" />
  </div>
</template>
<script>
import SecondBar from './SecondBar'
export default {
  name: 'NavBar',
  components: {
    SecondBar,
  },
  data() {
    return {
      contraido: false,
      visibleSecondBar: false,
      usuario: '',
      imagenUsuario: '',
    }
  },
  props: ['visible'],
  methods: {
    contraerMenu: function () {
      this.contraido = !this.contraido
      this.$emit('contraerMenu', this.contraido)
    },
    showSecondBar: function () {
      this.visibleSecondBar = true
    },
    toggleSecondBar: function () {
      this.visibleSecondBar = !this.visibleSecondBar
    },
    hideSecondBar: function () {
      this.visibleSecondBar = false
    },
    setSesionCliente: function () {
      const sesion_cliente = JSON.parse(localStorage.getItem('sesion_cliente'))
      this.usuario = sesion_cliente.nombre
      this.imagenUsuario = sesion_cliente.imagen
    },
  },
  computed: {
    isCompact() {
      return this.contraido === true ? ['text-center', 'pr-4'] : ''
    },
    isActive() {
      return this.visibleSecondBar === true ? 'active' : ''
    },
  },
  mounted() {
    this.setSesionCliente()
  },
}
</script>

<style scoped lang="scss">
.contraerMenu {
  cursor: pointer;
  &:hover {
    color: var(--tercero);
  }
}
#sidebar {
  background: url(../assets/img/background-menu.png);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 182px;
  transition: all 1s ease;
  position: relative;
  &.compact {
    width: 70px;
  }
}

#nav {
  ul {
    padding-left: 0;
    line-height: 1.7;
    list-style: none;
    li {
      margin-bottom: 15px;
      a {
        color: var(--gray);
      }
    }
  }
}
#configuracion {
  position: absolute;
  bottom: 15px;
  width: calc(100% - 14px);
  left: 14px;
  &Principal {
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 7px;
    width: 100%;
    display: block;
    border-radius: 100px 0 0 100px;
    margin-top: 15px;
    span {
      margin-left: 6px;
    }
    &:hover {
      background: var(--secundario);
    }
  }
}
#sesion {
  &Imagen {
    width: 41px;
    margin-right: 10px;
    position: relative;
  }
  &Estado {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
  p {
    margin-bottom: 0;
    color: var(--blanco);
  }
}
</style>
